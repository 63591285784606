*, *::after, *::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  align-items: center;
  justify-content: center;
}

body {
    overflow-x: hidden;
    scrollbar-gutter: stable;
    background-color: var(--primary-background-color);
}

:root {
    --primary-font:'Libre Baskerville', serif;;
    --secondary-font:'Work Sans', sans-serif;
    --titles-font:'Antic Didone', serif;
    --primary-background-color: rgb(205, 184, 144);
    --footer-background-color: #202124;
    --hover-color: #c89a63;
}

.no-scroll {
    overflow: hidden;
}

.App {
    overflow-x: hidden;
    font-family: var(--primary-font);
    background-color: var(--primary-background-color);
}

a:link {
  color: white; 
  text-decoration: none;
  cursor: pointer;
}

a:visited {
    color: white; 
    text-decoration: none;
}

ul li,
ol li {
  list-style: none;
}

ul li a:link,
ol li a:link {
  color: black;
  text-decoration: none;
}

ul li a:visited,
ol li a:visited {
  color: black; 
  text-decoration: none;
}

::-webkit-scrollbar {
    background-color: rgb(0, 0, 0);
    width: 4px;
}

::-webkit-scrollbar-thumb {
    border-radius: 3em;
    width: 4px;
    background-color: rgb(255, 255, 255);
}

.color {
    color: var(--hover-color);
    pointer-events: none;
    border: 1px solid var(--hover-color);
    display: flex;
    padding: .3rem .4rem;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.color-mobile {
    color: var(--hover-color);
    pointer-events: none;
    display: flex;
}

/* Home */

.home-container {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100%;
    width: 100vw; 
    height: 100%;
    overflow: hidden;
    background-color: var(--primary-background-color);
}

.background-photo-container {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}

.background-first-photo {
    position: absolute;
    left: 0;
    width: 100vw;
    min-height: 100vh;
    max-width: auto;
    object-fit: cover;
}

.background-first-photo-mobile {
        position: absolute;
        left: 0;
        width: 100vw;
        min-height: 100vh;
        max-width: auto;
        object-fit: cover;
}

.first-home-slide {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Header */

.header-overlaid {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 13vh;
    background-image: linear-gradient(#000000 , rgba(199, 184, 157, 0.188));
}

.main-header {
    width: 100%;
    height: 100%;
    padding: 2vh 3vw 2vh 3vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.name {
    color: white;
    font-weight: 300;
    font-size: 1.5rem;
    letter-spacing: .09rem; 
}

.navigation-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 35%;
}

.hover-links {
    transition: color .3s ease-in-out;
}

.hover-links:hover {
    color: var(--hover-color);
}

.navigation-social-media {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 6.5vw;
}

.navication-extras-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.navigation-languages-home {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: .6rem;
    margin-right: 1.5rem;
}

.links-circle {
    display: flex;
    padding: .3rem .4rem;
    border: 1px solid var(--primary-background-color);
    text-align: center;
    justify-content: center;
    align-items: center;
    transition: border;
}

.links-circle:hover {
    border: 1px solid var(--hover-color);
}

.navigation-icons {
    fill: var(--primary-background-color);
    transition: transform .2s ease-in-out;
}

.navigation-icons:hover {
    transform: scale(1.1);
}

.down-arrow-bio {
    position: absolute;
    bottom: 5vh;
    left: 50%;
}

.down-arrow {
    cursor: pointer;
    width: 24px;
    height: 24px;
    animation: bounce 3s infinite;
    fill: #ffffff;
    transition: fill 0.3s ease-in-out;
}

.down-arrow:hover {
    fill: var(--hover-color);
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

.second-home-slide {
    display: flex;
    width: 100%;
    height: 100vh;
    z-index: 2;
    background-color: var(--primary-background-color);
}

.home-bio-text-container {
    display: flex;
    width: 50%;
    height: 100%;
    flex-direction: column;
    align-self: flex-start;
    align-items: flex-start;
    justify-content: space-evenly;
    background-color: var(--primary-background-color);
    padding: 10vh 5vw 10vh 5vw;
}

.home-biography {
    font-size: 7rem;
    opacity: .9;
    font-weight: 200;
    color: #636466;
    font-family: var(--titles-font);
}

.home-concerts {
    font-size: 5.5rem;
    opacity: .9;
    font-weight: 200;
    color: #636466;
    font-family: var(--titles-font);
}

.small-bio-home {
    color: #636466;
    font-family: var(--secondary-font);
    font-weight: 500;
}

.white-font {
    color: white;
    transition: color .5s ease-in-out;
    font-size: 1.125rem;
    font-family: 'Work Sans', sans-serif;
}

.biography-button-more {
    text-align: center;
    background-color: transparent;
    border: 1px solid white;
    padding: .75rem 1.5rem;
    transition: background-color .5s ease-in-out;
}

.biography-button-more:hover {
    background-color: white;
}

.biography-button-more:hover .white-font {
    color: black;
}

.home-bio-photo-container {
    display: flex;
    position: relative;
    width: 50vw;
    height: 100%;
    align-self: flex-start;
    overflow: hidden;
}

.home-bio-photo-container-mobile {
    display: none;
}

.main-first-photo {
    position: relative;
    top: -5vh;
    left: 0;
    width: 100%;
}

.third-home-slide {
    z-index: 2;
    background-color: var(--primary-background-color);
    display: flex;
    width: 100%;
    height: 100vh;
}

.home-concerts-container {
    display: flex;
    width: 50%;
    height: 100%;
    flex-direction: column;
    align-self: flex-start;
    align-items: flex-start;
    justify-content: space-evenly;
    background-color: var(--primary-background-color);
    padding: 0vh 5vw 10vh 5vw;
}

.upcoming-concert-home-container {
    display: flex;
    background-color: rgba(60, 60, 60, 0.318);
    flex-direction: column;
    align-items: flex-start;
    border: 1px solid white;
    color: white;
    height: fit-content;
    padding: 1.5rem;
    width: 70%;
    margin: 2vh 0 5vh 0;
}

.home-date-time-container {
    display: flex;
    flex-direction: row;
    border: 1px solid white;
    width: fit-content;
    padding: .4rem;
    align-items: center;
    font-family: var(--secondary-font);
    margin-bottom: 2rem;
}

.home-date-concert {
    margin-right: 1rem;
}

.home-concert-name {
    font-family: var(--titles-font);
    margin-bottom: 2rem;
    font-size: 1.3rem;
}

.home-place-concert-container {
    font-family: var(--secondary-font);
    min-height: 14vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

/* Footer */

.footer {
    width: 100vw;
    background-color: var(--footer-background-color);
    height: fit-content;
    font-size: 1rem;
}

.footer-container {
    color: white;
    height: 100%;
    padding: 2vh 3vw 2vh 3vw;
    display: flex;
    flex-direction: row;
    width: 100%;
    font-size: .8rem;
    align-items: center;
    justify-content: space-between;
}

/* Bio */

.bio-biography-container {
    width: fit-content;
    display: flex;
    flex-direction: column;
}

.bio-container {
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--primary-background-color);
}

.image-bio-container {
    position: relative;
    height: 100vh;
    width: 100%;
    overflow: hidden;
}

.bio-image-background {
    position: absolute;
    top: -80vh;
    left: 0;
    width: 100%;
}

.bio-image-background-mobile {
    min-height: 100%;
    width: 100%;
}

.bio-quote-wrapper {
    position: absolute;
    top: 30vh;
    left: 3rem;
    display: flex;
    flex-direction: column;
    background-color: rgba(199, 184, 157, 0.664);
    width: 38vw;
    justify-content: space-between;
    align-items: center;
    padding: 4vh 1vw 4vh 1vw;
}

.home-quote {
    font-size: 1.3rem;
    letter-spacing: .09rem;
    font-style: italic;
    width: fit-content;
    text-align: center;
}

.quoter {
    margin-top: 1.5rem;
    font-size: 1rem;
    opacity: .6;
}

.main-bio-container {
    position: relative;
    z-index: 4;
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: var(--primary-background-color);
}

.main-bio-quote-wrapper {
    position: relative;
    display: flex;
    height: fit-content;
    flex-direction: column;
    background-color: #6364666c;
    justify-content: space-between;
    align-items: center;
    padding: 3rem 4rem 3rem 4rem;
    margin: 2rem -6rem 3rem -6rem;
}

.bio {
    font-family: var(--primary-font);
    color: white;
    font-style: italic;
    font-size: 1.4rem;
}

.bio-second {
    font-family: var(--primary-font);
    color: white;
    font-style: italic;
    font-size: 1.8rem;
}   

.bio-biography {
    font-size: 7rem;
    opacity: .9;
    font-weight: 200;
    color: #636466;
    font-family: var(--titles-font);
}


.line {
    width: 100%;
    border-top: 1.5px solid #636466;
    margin-top: -1.5rem;
    opacity: .8;
    margin-bottom: 4rem;
}

.biography-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-right: 1px solid rgba(255,255,255,.3);
    border-left: 1px solid rgba(255,255,255,.3);
    width: 70%;
    padding: 4rem 4rem 8rem 4rem;
}

.biography-text {
    color: #646464;
    font-family: var(--secondary-font);
    margin-bottom: 2rem;
    font-size: 1.2rem;
}

.margin-big {
    margin-top: 2rem;
    margin-bottom: 4rem;
}

.contact-links-container  {
    display: flex;
    flex-direction: row;
    align-self: center;
    justify-content: space-between;
    align-items: center;
    margin-top: 5rem;
    font-size: 1.5rem;
}

.contact-links-container:hover .links-contact {
    color: var(--hover-color);
}

/* Concerts */

.margin-left {
    margin-left: 1rem;
}

.calendar-container {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100%;
}

.calendar-body {
    display: grid;
    background-color: var(--primary-background-color);
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.image-calendar-container {
    display: flex;
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.concert-image-background {
    width: 100%;
    position: absolute;
    top: -50vh;
    left: 0;
    min-height: 100vh;
}

.calendar-body {
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 3rem 4rem;
}

.calendar-title-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: fit-content;
}

.calendar-title {
    font-size: 7rem;
    opacity: .9;
    font-weight: 200;
    color: #636466;
    font-family: var(--titles-font);
}

.line2 {
    width: 100%;
    border-top: 1.5px solid #636466;
    margin-top: -1.5rem;
    opacity: .8;
    margin-bottom: 4rem;
}

.concerts-grid-container {
    width: 100%;
    margin-bottom: 8rem;
}

.concerts-grid {
    width: 100%;
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;
}

.concert-container {
    width: 100%;
    height: 100%;
    max-width: 25rem;
    border: 1px solid #636466;
    flex-direction: column;
    align-items: center;
    padding: 1.5rem;
    position: relative;
    color: white;
    font-family: var(--secondary-font);
    font-size: 1rem;
    line-height: 1.5;
}

.concert {
    height: 100%;
}

.concert-layout {
    height: 100%;
    flex-direction: column;
    display: flex;
    position: relative;
    align-items: flex-start;
    justify-content: space-between;
}

.date-time-concert {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: fit-content;
    background-color: #6364669f;
    padding: .4rem .3rem .4rem .3rem;
}

.concert-title-container {
    margin-top: 1.2rem;
    margin-bottom: 1rem;
}

.concert-title {
    font-size: 1.4rem;
    font-family: var(--titles-font);
    font-weight: 500;
}

.concert-venue {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    justify-content: space-between;
    align-items: start;
}

.concert-participants {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.3rem;
    align-items: flex-start;
    justify-content: flex-start;
}

.more-button-concert {
    margin-bottom: 1rem;
}

.inactive-button {
    pointer-events: none;
}

.button-more {
    display: flex;
    flex-direction: row;
    border: 1px solid white;
    font-size: 1.1rem;
    padding: .35rem 0.75rem;
    transition: background-color 0.4s ease-in-out, border-color 0.4s ease-in-out, color 0.4s ease-in-out;
}

.more-infos-icon {
    width: 0.8rem;
    height: 0.8rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
}

.button-more:hover {
    background-color: #ffffff;
    border-color: #636466; 
    color: #636466;
}

/* Media */

.media-container {
    width: 100%;
    height: 100%;
    background-color: var(--primary-background-color);
    overflow-x: hidden;
}

.media-body {
    width: 100%;
    height: 100%;
    padding-top: 24vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.media-upcoming-container {
    position: relative;
    width: fit-content;
    display: flex;
    align-self: flex-start;
    flex-direction: column;
    height: 20%;
    justify-content: space-between;
}

.media-name-container {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
}

.line3 {
    width: 100%;
    border-top: 1.5px solid #636466;
    margin-top: -.6rem;
    opacity: .8;
    margin-bottom: 3rem;
}

.media-title {
    font-size: 7.5rem;
    opacity: .9;
    font-weight: 200;
    color: #636466;
    font-family: var(--titles-font);
}

.media-links-container {
    display: flex;
    flex-direction: row;
    margin-top: 4rem;
    align-self: center;
    font-family: var(--secondary-font);
    font-size: 1.2rem;
    justify-content: space-between;
    width: 35%;
}

.contact-heading {
    display: flex;
    align-self: flex-start;
    margin-top: 5vh;
    margin-bottom: 5vh;
    font-size: 2rem;
    font-family: var(--primary-font);
    font-weight: 300;
    color: white;
}

.media-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0 5vw 10vh 5vw;
}

.photos-media {
    margin-bottom: 1rem;
}

.button-container {
    display: flex;
    flex-direction: row;
    align-self: center;
    justify-content: space-between;
    width: 70%;
    height: fit-content;
    margin-bottom: 2vh;
}

.btn-media {
    cursor: pointer;
    font-family: var(--primary-font);
    border: 1.5px solid white;
    padding: 2rem 4.5rem 2rem 4.5rem;
    color: white;
    opacity: .3;
    font-size: 1.2rem;
    transition: opacity .3s;
}

.btn-media:hover {
    opacity: 1;
}

.videos-container {
    margin-top: 7vh;
    width: 100%;
    height: 100%;
    display: grid;
    justify-content: left;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr; 
    row-gap: 6rem;
    column-gap: 3rem;
    margin-bottom: 13rem;
}

.audios-container {
    font-family: var(--secondary-font);
    display: flex;
    flex-direction: column;
    margin-top: 7vh;
    width: 100%;
    height: 100%;
    margin-bottom: 13rem;
}

.plylist-button {
    cursor: pointer;
    background: #636466;
    color: white;
    border: none;
    width: 7rem;
    height: 3rem;
    padding: .6rem 1.5rem;
    border-radius: 5%;
    outline: none;
    font-size: 1rem;
    text-transform: uppercase;
    transition: all 0.4s ease;
    margin-right: 1rem;
    font-weight: 300;
}

.plylist-button:hover {
  background: var(--hover-color);
}

.canvases {
    height: 10rem;
}

#volume {
    margin-right: 1rem;
}

.volume {
    font-size: 1.2rem;
    color: white;
}

.playlist {
  text-align: left;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.playlist-item {
  color: #444;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  cursor: pointer;
  padding: 1em;
  transition: background .4s;
}

.playlist-item.selected {
  font-weight: bold;
}

.playlist-item:hover {
  background: rgba(0, 0, 0, 0.1);
}

.controls {
    margin-top: 2rem;
    margin-bottom: 2rem;
    text-align: left;
    padding: 1em;
}

.video-responsive {
    position: relative;
}

#video {
    width: 450px;
    height: 350px;
}

.photos-container {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 20fr 20fr;
    margin-bottom: 8vh;
    height: 100%;
}

.photos-container img {
    cursor: pointer;
    width: 350px;
}

.photos-container,
.videos-container,
.audios-container {
  opacity: 0;
  transition: opacity 1s ease,  transform .5s ease;;
}

.photos-container.visible,
.videos-container.visible,
.audios-container.visible {
  opacity: 1;
  transform: translateY(-10px);
}

.btn-media.active {
    pointer-events: none;
    opacity: 1;
}

/* Video */


.video-name {
    margin-top: 2vh;
    font-size: 1.125rem;
    letter-spacing: .2px;
    color: white;
    font-family: Inter-VQ,sans-serif;
    font-weight: 300;
    line-height: 1.5;
}

.video-container {
    margin-bottom: 8vh;
}

/* Carousel */

.custom-carousel {
    background-color: rgba(199, 184, 157, 0.949);
    width: 100vw;
    height: 100vh;
    z-index: 200;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 2vh 0 2vh 0;
}

.close-button {
    z-index: 100;
    position: absolute;
    top: 5%;
    right: 5vw;
    border: none;
    outline: none;
    background: none;
    cursor: pointer;
    font-size: 2rem;
    color: white;
    transition: color .3s;
    padding: 0;
    margin: 0;
}

.close-button:hover {
    color: var(--hover-color);
}

.left-button {
    position: absolute;
    top: 50%;
    left: 5vw;
    border: none;
    outline: none;
    background: none;
    cursor: pointer;
    font-size: 3rem;
    color: white;
    transition: color .3s;
    padding: 0;
    margin: 0;
}

.left-button:hover {
    color: var(--hover-color);
}

.right-button {
    position: absolute;
    top: 50%;
    right: 5vw;
    border: none;
    outline: none;
    background: none;
    cursor: pointer;
    font-size: 3rem;
    color: white;
    transition: color .3s;
    padding: 0;
    margin: 0;
}

.right-button:hover {
    color: var(--hover-color);
}

.main-image {
    height: 80vh;
}

.photographer {
    position: relative;
    color: var(--hover-color);
    z-index: 10;
    font-size: .8rem;
    transition: color .3s;
}

.photographer:hover {
    color: var(--hover-color);
}

.fade-out {
    opacity: 0;
    transition: opacity 1s ease-in-out;
}

.visibleImage {
    opacity: 1;
    transition: opacity 1s ease-in-out;
}

.hiddenImage {
    opacity: 0;
    transition: opacity 1s ease-in-out;
}

.main-image img {
    position: absolute;
    top: 43%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 80vh;
    width: auto;
}

.thumbnails {
    display: flex;
    margin-top: 3vh;
    flex-direction: row;
    align-items: center;
    width: 80vw;
    justify-content: space-between;
}

.thumbnail img {
    height: 13vh;
    width: auto;
    cursor: pointer;
}

.thumbnail.selected {
    border: 2px solid rgb(255, 255, 255);
}

/* Contact */

.svg {
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.contact-container {
    width: 100vw;
    height: 100%;
    background-color: var(--primary-background-color);
}

.contact-background-photo-container {
    display: none;
}

.contact-body {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
}

.contact-main {
    display: flex;
    flex-direction: column;
    padding-left: 5rem;
    align-items: flex-start;
    justify-content: flex-start;
    width: 50%;
    height: fit-content;
}

.contact-title {
    font-size: 4rem;
    opacity: .9;
    font-weight: 200;
    color: #636466;
    font-family: var(--titles-font);
}

.first-contact-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
}

.social-media-container {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 55%;
    color: white;
    transition: color .2s ease-in-out;
}

.contact-icons {
    display: block;
    width: 2rem;
    height: 2rem;
}

.links-infos {
    margin-left: 2rem;
    padding-top: .4rem;
}

.image-wrapper {
    display: flex;
    position: relative;
    width: 50%;
    height: 100%;
    overflow: hidden;
}

.contact-background-image {
    display: flex;
    position: absolute;
    top: -15vh;
    right: 0;
    width: 100%;
    height: auto;
}

.social-media-container:hover {
    color: var(--hover-color);
}

.margin-contact {
    margin-bottom: 3rem;
}

/* Extras */

.media-link {
    font-size: 1.1rem;
    align-self: flex-start;

}

/* Hamburger */

.hamburger-main-header {
    width: 100vw;
    display: flex;
    height: fit-content;
    justify-self: center;
    flex-direction: row;
    z-index: 3;
    font-size: .8em;
    font-weight: 300;
    padding: 1% 5% 1% 5%;
    justify-content: space-between;
}

.hamburger-container{
    display: none;
}

.hamburger {
    width: 3rem;
}

.hamburger-menu-container {
    position: fixed;
    top: -150vh;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    padding-top: 35%;
    transition: top 1s;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: #000000;
    z-index: 30;
}

.hamburger-menu-container.open {
    top: 0;
}

.hamburger-menu-container.closed {
    top: -150vh;
}

.header-navigation-hamburger {
    margin-left: 5%;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 50%;
    width: 30vw;
    align-items: flex-start;
    justify-content: space-between;
    font-family: var(--secondary-font);
    font-size: 1.5rem;
}

.text-hamburger {
    font-size: 2rem;
}

.navigation-social-media-hamburger {
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 3vh;
}

.navigation-languages-hamburger {
    display: flex;
    flex-direction: row;
    width: 50%;
    justify-content: space-between;
    font-size: 2rem;
    padding-bottom: 2vh;
}

.navigation-hamburger {
    display: flex;
    flex-direction: column;
    height: 30%;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 1.8rem;
}

/* Different Sizes */

@media (max-width: 1200px) {

    .navigation-bar {
        font-size: .9rem;
    }

    .main-first-photo {
        position: relative;
        top: 0vh;
        left: 0;
        width: 100%;
        min-height: 100%;
    }

    .contact-background-image {
        top: 0;
    }
}

@media (max-width: 1024px) {

    .main-first-photo {
        position: relative;
        top: 0vh;
        left: 0;
        width: 100%;
        min-height: 100%;
    }

    .contact-background-image {
        top: 0;
    }

    .photographer {
        top: -4rem;
    }

    .color {
        color: var(--hover-color);
    }
    

    .header-overlaid{
        z-index: 100;
    }

    body {
        scrollbar-gutter: auto;
    }

    .navigation-bar {
        display: none;
    }

    .navication-extras-container {
        display: none;
    }

    .main-header {
        display: none;
    }

    .hamburger-container{
        display: block;
    }

    .name {
        font-size: 2.1rem;
        letter-spacing: .09rem; 
    }

    /* Home Phone */

    .white-font {
        font-size: 1.8rem;
    }

    .second-home-slide {
        display: flex;
        flex-direction: column;
        z-index: 2;
        height: 100%;
    }

    .home-bio-text-container {
        width: 80%;
        padding: 5rem 3rem;
        font-size: 1.3rem;
    }

    .home-bio-photo-container {
        display: none;
    }

    .home-bio-photo-container-mobile {
        display: block;
        position: relative;
        width: 100%;
        height: 80vh;
        overflow: hidden;
    }

    .main-first-photo-mobile {
        position: absolute;
        top: -20vh;
        left: 0;
        width: 100%;
    }

    .home-biography {
        font-size: 3.25rem;
        margin-bottom: 2rem;
    }

    .home-bio-text-container {
        width: 90%;
        padding: 5rem 3rem;
        font-size: 1.6rem;
    }

    .small-bio-home {
        margin-bottom: 2rem;
    }

    .third-home-slide {
        padding: 5rem 3rem;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .home-concerts {
        font-size: 3.25rem;
        margin-bottom: 2rem;
    }

    .home-concerts-container {
        width: 80%;
        padding: 0;
    }

    .upcoming-concert-home-container {
        font-size: 1.4rem;
    }

    .home-concert-name {
        font-size: 2rem;
    }

    /* Bio */

    .bio-second {
        font-size: 1.3rem;
    }
    
    .biography-container {
        width: 80%;
        padding: 0 2rem 5rem 2rem;
    }

    .bio-quote-wrapper {
        left: 52vw;
        top: 50vh;
        width: 45vw;
        padding: 2rem 1rem;
    }

    .bio-biography {
        margin-top: 2rem;
        font-size: 5.5rem;
    }

    .line {
        margin-top: -1rem;
        width: 100%;
    }

    .biography-text {
        font-size: 1.3rem;
        margin-bottom: 1.5rem;
    }

    /* Concert */

    .concert-image-background {
        top: 0;
    }

    .calendar-title-container {
        width: fit-content;
    }

    .line2 {
        margin-top: -.8rem;
    }

    .calendar-title {
        font-size: 4rem;
    }

    .concerts-grid {
        width: 100%;
        grid-column-gap: 2rem;
        grid-row-gap: 2rem;
        grid-template-rows: auto;
        grid-template-columns: 1fr 1fr;
        grid-auto-columns: 1fr;
        display: grid;
    }

    .margin-left {
        margin-left: 1rem;
    }

    .date-time-concert {
        width: fit-content;
    }

    .concert-layout {
        font-size: 1.2rem;
    }

    .concert-title {
        font-size: 1.6rem;
    }

    /* Media */

    .media-body {
        padding-top: 15vh;
    }

    .media-title {
        font-size: 6rem;
    }

    .line3 {
        margin-top: -1rem;
    }

    .button-container {
        width: 100%;
        margin-top: 10%;
    }
    
    .left-button {
        display: none;
    }

    .right-button {
        display: none;
    }

    .photos-container {
        margin-top: 5%;
        display: grid;
        width: 100%;
        column-gap: 2rem;
        row-gap: 2rem;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 20fr 20fr;
        margin-bottom: 8vh;
        height: 100%;
    }

    .media-link {
        font-size: 1.5rem;
    }
    
    .main-image img {
        position: absolute;
        top: 43%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-height: 77vh;
        max-width: 95vw;
        width: auto;
        height: auto;
    }

    .thumbnails {
        margin-top: 0;
        overflow-x: scroll;
        white-space: nowrap; 
        scrollbar-width: none;
        -ms-overflow-style: none;
    }

    .thumbnails div {
        margin-right: 5%;
    }

    .thumbnail img  {
        height: 14vh;
    }

    .thumbnails::-webkit-scrollbar {
        display: none;
    }

    .videos-container {
        height: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 0;
    }

    #video {
        width: 650px;
        height: 450px;
    }

    .video-name {
        font-size: 1.6rem;
    }

    .photos-container img {
        cursor: pointer;
        width: 100%;
    }

    /* Cotact */

    .contact-body {
        height: 100vh;
        display: flex;
        flex-direction: column;
    }

    .contact-background-photo-container {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        overflow: hidden;
    }

    .contact-image-background {
        width: 110%;
        height: auto;
        left: -10vw;
    }

    .image-wrapper {
        display: none;
    }

    .contact-main {
        position: absolute;
        top: 0;
        left: 0;
        
        display: flex;
        align-items: flex-start;
        justify-content: center;
        padding-left: 8%;
        width: 100%;
        height: 100%;
    }

    .contact-title  {
        color: #aaaaab;
        font-size: 4.5rem;
    }

    .margin-contact {
        margin-bottom: 4rem;
    }
}

@media (max-width: 540px) {

    .photographer {
        top: -5rem;
    }

    .footer-container {
        font-size: .6rem;
    }

    .video-container {
        width: 95vw;
    }

    /* Home */

    .header-navigation-hamburger {
        height: 70%;
    }

    .name {
        font-size: 1.5rem;
        letter-spacing: 0;
    }

    .home-bio-text-container {
        width: 100%;
    }

    .third-home-slide {
        padding-bottom: 0;
        padding-top: 0;
        margin-bottom: 10%;
        margin-top: 10%;
    }

    .upcoming-concert-home-container {
        width: 80%;
        height: 100%;
    }

    .home-concerts-container {
        width: 100%;
    }

    .home-concerts {
        width: 100%;
    }

    .home-place-concert-container {
        height: fit-content;
    }

    .white-font {
        font-size: 1.5rem;
    }

    /* Bio */

    .biography-text {
        color: #4a4a4a;
        font-size: 1.2rem;
    }

    .bio {
        font-size: 1rem;
    }

    .bio-second {
        font-size: 1rem;
    }

    /* Calendar */

    .calendar-body {
        padding: 0 1rem 0 1rem;
    }

    .concerts-grid {
        grid-column-gap: 1rem;
    }

    .concert-container {
        padding: 1.5rem .5rem 1.5rem 1rem;
    }

    .concert-layout {
        font-size: 1rem;
    }

    /* Media */

    .custom-carousel {
        height: 100%;
    }

    .media-title {
        font-size: 5rem;
    }
    
    .btn-media {
        padding: 1.5rem 2rem;
    }

    .media-wrapper {
        padding: 0 1em 10% 1em;
    }

    .photos-container {
        display: flex;
        flex-direction: column;
    }

    #video {
        width: 100%;
        height: 330px;
    }

    .video-name {
        font-size: 1.2rem;
    }

    .media-link {
        font-size: 1.3rem;
    }
}

@media (max-width: 530px) {
    /* Home */

    .main-first-photo-mobile {
        top: 0;
    }

    .navigation-languages-hamburger  {
        width: 80%;
    }

    .hamburger {
        margin-left: 1.5rem;
        width: 1.5rem;
    }

    .header-overlaid {
        width: 100vw;
    }

    .background-first-photo-mobile {
        height: auto;
        width: 100%;
        min-height: 100vh;
        position: relative;
    }

    .hamburger-main-header {
        padding: 0 1rem 0 1rem;
    }

    .hamburger-main-header {
        padding-top: .5rem;
    }

    .name {
        font-size: 1.2rem;
    }

    .home-bio-text-container {
        padding: 3rem 1rem;
    }

    .home-bio-photo-container-mobile {
        height: 100%;
    }

    .main-first-photo-mobile {
        position: relative;
    }

    .third-home-slide {
        margin: 0;
        padding: 3rem 1rem 5rem 1rem;
    }

    .home-concerts {
        font-size: 2.5rem;
    }

    .upcoming-concert-home-container {
        font-size: 1rem;
    }

    .small-bio-home {
        color: #4a4a4a;
        font-size: 1.3rem;
    }

    /* Bio */

    .line {
        width: 100%;
    }

    .bio-quote-wrapper {
        display: none;
    }

    .bio-image-background-mobile {
        height: 120%;
        width: auto;
        position: relative;
        left: 0vw;
        top: -5vh;
    }

    .bio-biography {
        font-size: 4rem;
    }

    .biography-container {
        width: 90%;
        padding: 0 1rem 5rem 1rem;
    }

    .line {
        margin-top: -.5rem;
    }

    .main-bio-quote-wrapper {
        padding: 1rem .5rem;
        height: fit-content;
        margin: 1.5rem -2rem ;
    }

    /* Concerts */

    .concert-container {
        padding: 1.5rem;
    }

    .calendar-body {
        padding-top: 3rem;
    }

    .concert-image-background {
        width: auto;
        height: 100%;
        position: relative;
    }

    .concerts-grid {
        display: flex;
        flex-direction: column;
    }

    .button-more {
        font-size: 1.4rem;
    }

    .concert-title {
        font-size: 2rem;
    }

    .concert-layout {
        font-size: 1.2rem;
    }

    /* Media */

    .close-button {
        top: 2vh;
        font-size: 1.5rem;
    }

    .main-image img {
        position: absolute;
        top: 43%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-height: 68vh;
        max-width: 95vw;
        width: auto;
        height: auto;
    }

    .video-container {
        width: 95vw;
    }

    .btn-media  {
        padding: .4rem;
    }

    #video {
        width: 100%;
        height: 320px;
    }

    /* Contact */

    .contact-image-background {
        height: 120%;
        position: relative;
        width: auto;
        right: -10vw;
    }

    .contact-main {
        padding-top: 0;
        justify-content: center;
    }

    .contact-title {
        font-size: 2.5rem;
    }

    .line3 {
        margin-top: -.5rem;
    }
}

@media (max-width: 1024px) and (orientation: landscape) {

    .header-overlaid {
        width: 100vw;
        height: 15vh;
    }
    
    .hamburger-menu-container {
        width: 100%;
        height: 100%;
        min-height: 100vh;
        padding-top: 40vh;
        overflow-y: scroll;
    }
    
    .hamburger-menu-container.closed {
        top: -200%;
    }

    .first-home-slide {
        display: flex;
        min-height: 150vh;
    }

    .background-photo-container {
        width: 100vw;
        height: fit-content;
    }

    .background-first-photo-mobile {
        top: -30vh;
        width: 100vw;
        min-height: 100%;
    }

    .home-bio-photo-container-mobile {
        height: 150vh;
    }

    .main-first-photo-mobile {
        top: -30vh;
    }

    .second-home-slide {
        position: relative;
    }

    /* Bio */

    .bio-quote-wrapper {
        top: 17vh;
    }

    /* Concerts */

    .concert-image-background {
        top: -20vh;
    }

    /* Calendar */

    .image-calendar-container {
        height: 150vh;
    }
}

@media (max-height: 430px) and (orientation: landscape) {

    .bio-second {
        font-size: 1.2rem;
    }

    .main-image img {
        position: absolute;
        top: 48%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-height: 84vh;
        max-width: 95vw;
        width: auto;
        height: auto;
    }

    .thumbnails {
        z-index: 2;
        position: absolute;
        bottom: 2vh;
    }
    
    .custom-carousel {
        height: 100%;
    }

    .header-navigation-hamburger {
        height: fit-content;
        row-gap: 1rem;
    }

    .hamburger-menu-container {
        padding-bottom: 10%;
    }

    .background-first-photo-mobile {
        top: -70%;
    }

    .btn-media {
        padding: 1rem 2rem;
    }

    .media-body {
        padding-top: 30vh;
    }

    .header-overlaid {
        height: fit-content;
    }

    .home-place-concert-container {
        height: fit-content;
    }

    .bio-quote-wrapper {
        display: none;
    }

    .bio-image-background-mobile {
        position: relative;
        top: -20vh;
    }

    /* Contact */

    .contact-body {
        height: 180vh;
    }

    .contact-title {
        font-size: 3rem;
    }
    
    .line3 {
        margin-top: -0.5rem;
    }

    .contact-background-photo-container {
        height: 180vh;
    }

    .contact-main {
        padding-top: 50%;
    }

    .social-media-container {
        font-size: 1.5rem;
    }
}

@media (min-width: 768px) 
  and (max-width: 1180px)
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 2) {
    
    .background-first-photo {
        height: auto;
        width: 100%;
        top: 0;
    }

    .main-first-photo {
        top: 0;
    }

    .bio-image-background {
        top: -70vh;
    }

    .contact-background-image {
        top: 0;
    }
    
}

@media (min-width: 680px) 
  and (max-width: 720px)
  and (orientation: landscape) {

    .contact-image-background {
        top: -20vh;
        position: absolute;
    }

    .contact-title {
        font-size: 2rem;
    }

    .line3 {
        margin-top: -.2rem;
    }

    .contact-main {
        padding-top: 10%;
    }

    .social-media-container {
        font-size: 1.3rem;
    }
}

@media (max-width: 280px) {

    .margin-contact {
        margin-bottom: 1rem;
    }

    .name {
        font-size: 1rem;
    }

    .btn-media {
        padding: .2rem;
        font-size: 1rem;
    }

    .footer-container {
        font-size: .6rem;
        column-gap: .4rem;
        text-align: center;
    }
}

@media (min-width: 600px) 
  and (max-height: 290px)
  and (orientation: landscape) {

    .contact-main {
        padding-top: 40%;
    }

    .line3 {
        margin-bottom: .5rem;
    }

    .margin-contact {
        margin-bottom: .5rem;
    }
}

@media (min-width: 1024px) 
  and (-webkit-min-pixel-ratio: 2) 
  and (orientation: landscape) {

    .contact-background-image {
        top: 0;
    }

    .main-first-photo {
        top: 0;
    }

    .social-media-container {
        font-size: 1.5rem;
    }
}

@media (min-width: 1336px) {

    .name {
        font-size: 2rem;
    }

    .navigation-languages-home {
        font-size: 1.2rem;
    }

    .small-bio-home {
        font-size: 1.4rem;
    }

    .white-font {
        font-size: 1.5rem;
    }

    .home-place-concert-container {
        font-size: 1.4rem;
    }

    .home-concert-name {
        font-size: 1.6rem;
    }

    .home-date-time-container {
        font-size: 1.4rem;
    }

    .footer {
        font-size: 1.5rem;
    }

    .bio {
        font-size: 1.8rem;
    }

    .biography-text {
        font-size: 1.2rem;
    }

    .biography-container {
        width: 50%;
    }

    .main-bio-quote-wrapper {
        margin: 3rem -8rem;
    }

    .footer {
        position: relative;
        z-index: 2;
    }

    .calendar-body {
        padding-top: 10vh;
        padding-bottom: 10vh;
        min-height: 100vh;
    }

    .concerts-grid {
        row-gap: 10vh;
    }

    .line2 {
        margin-bottom: 10vh;
    }

    .concert-image-background {
        position: relative;
        top: 40vh;
    }

    #video {
        width: 550px;
        height: 350px;
    }

    .video-name {
        font-size: 1.8rem;
    }

    .btn-media  {
        font-size: 2rem;
    }

    .media-link {
        font-size: 2rem;
    }

    .social-media-container {
        align-items: center;
        font-size: 2rem;
    }
}

@media (min-width: 1920px) {

    #video {
        width: 800px;
        height: 600px;
    }
}


@media (min-width: 1536px) {

    .video-container {
        margin-bottom: 0rem;
    }
    
    #video {
        width: 700px;
        height: 480px;
    }
}